<template>
	<v-container fill-height scroll-y fluid>
		<v-layout align-start justify-center>
			<v-flex xs10 sm8 md6 xl4>
				<v-card flat class="noBackground">
					<v-card-title>
						<v-layout align-center justify-center my-3 row>
							<v-flex xs12 sm10 md8 xl6>
								<v-img v-if="logo" :src="logo" contain :max-height="$vuetify.breakpoint.mdAndUp ? '25vh' : '15vh'"></v-img>
							</v-flex>
						</v-layout>
					</v-card-title>
					<v-card-title>
						<v-layout align-center justify-center :display-1="$vuetify.breakpoint.mdAndUp" :title="$vuetify.breakpoint.smAndDown" text-xs-center>
							<span>{{ $t('signin.title') }}</span>
						</v-layout>
					</v-card-title>
					<v-card-text>
						<v-form>
							<v-text-field
								v-model="email"
								name="email"
								type="email"
								:label="$t('signin.fields.email')"
								:rules="[rules.required, rules.email]"
								prepend-icon="email"
								autocomplete
								autofocus
							></v-text-field>
							<v-text-field
								v-model="password"
								name="password"
								:type="revealPassword ? 'text' : 'password'"
								:label="$t('signin.fields.password')"
								:rules="[rules.required]"
								prepend-icon="lock"
								:append-icon="revealPassword ? 'visibility_off' : 'visibility'"
								autocomplete
								@click:append="revealPassword = !revealPassword"
							></v-text-field>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-layout align-center justify-center>
							<v-flex xs10 sm8 md6 xl4>
								<v-layout align-center justify-center my-3>
									<WButton full-width @click="signIn">{{ $t('signin.messages.button') }}</WButton>
								</v-layout>
								<v-layout align-center justify-center>
									<w-flex class="grey--text pointer text-underline" shrink @click="goTo('reset-password')">
										{{ $t('signin.messages.forgotten_password') }}
									</w-flex>
								</v-layout>
								<v-layout v-if="oauthProviders.length" align-center justify-center my-3>
									<v-divider class="mr-1" />
									<v-flex v-t="'or'" disabled shrink style="color: var(--v-softGrey-darken2)" text-xs-center />
									<v-divider class="ml-1" />
								</v-layout>
								<SignInSSOButtons :oauth-providers="oauthProviders" />
							</v-flex>
						</v-layout>
					</v-card-actions>
				</v-card>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex'
import AuthModuleGuard from '@/mixins/ModulesGuards/Auth/AuthModuleGuard'
import Validator from '@/mixins/Validator'
import OauthProviderService from '@/services/OauthProvider/OauthProviderService'

export default {
	name: 'SignIn',
	components: {
		SignInSSOButtons: () => ({
			component: import('@/components/SignIn/SignInSSOButtons')
		})
	},
	mixins: [AuthModuleGuard, Validator],
	data: function () {
		return {
			email: null,
			password: null,
			revealPassword: false
		}
	},
	computed: {
		...mapState({
			logo: state => state.whitelabel.selected.logo,
			isAuthenticated: state => state.auth.isAuthenticated,
			oauthProviders: state => state.oauthProviders.oauthProviders
		})
	},
	mounted: function () {
		window.addEventListener('keydown', this.signInWithEnter)
		this.getOauthProviders()
	},
	destroyed: function () {
		window.removeEventListener('keydown', this.signInWithEnter)
	},
	methods: {
		goTo: function (route) {
			this.appService.goTo(route)
		},
		signInWithEnter: function (e) {
			if (e.key === 'Enter') {
				this.signIn()
			}
		},
		signIn: function () {
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
			if (!this.email || this.email.trim().length === 0) {
				this.signInFailure(this.$t('signin.errors.missing_email'))
			} else if (!this.password || this.password.trim().length === 0) {
				this.signInFailure(this.$t('signin.errors.missing_password'))
			} else {
				this.doSignIn()
			}
		},
		doSignIn: function () {
			this.service
				.login(this.email, this.password)
				.then(() => {
					if (!this.isAuthenticated) {
						this.password = null
						this.signInFailure(this.$t('signin.errors.failed_authentication'))
					} else {
						let redirect = 'home'
						if (this.$route.query?.redirect) {
							const queryRedirect = decodeURIComponent(this.$route.query.redirect)
							if (queryRedirect.includes('/')) {
								redirect = {
									path: queryRedirect
								}
							} else {
								redirect = {
									name: queryRedirect
								}
							}
						}
						this.appService.goTo(redirect)
					}
				})
				.catch(() => {
					this.password = null
					this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('signin.errors.failed_authentication'))
				})
				.finally(() => {
					this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, 0)
				})
		},
		signInFailure: function (errormsg) {
			this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, errormsg)
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, 0)
		},
		getOauthProviders: function () {
			OauthProviderService.getOauthProviders()
		}
	}
}
</script>

<style scoped>
.noBackground {
	background-color: transparent !important;
}

button {
	width: 100%;
}

.underline:hover {
	text-decoration: underline;
}

img {
	margin: auto;
	width: 50px;
}

a {
	color: inherit;
	text-decoration: inherit;
}
</style>
